import axios from "axios";

//getPresellData 获取到预售产品
export function getPresellData(queryData) {
  return axios.get(`/w1/presell/goods`, {
    params: queryData,
  });
}

//addPresellOrder 添加预售订单
export function addPresellOrder(sku, number) {
  let postData={
    "sku": sku,
    "number": number,
  };
  return axios.post("/w1/presell/order", postData);
}


//getPresellInfo 获取预售[商品]详情
export function getPresellInfo(sku) {
  return axios.get(`/w1/presell/goodsInfo/${sku}`);
}

//=======================================================

//getPresellOrderInfo 获取预售[订单]详情
export function getPresellOrderInfo(ordersn) {
  return axios.get(`/w1/presellOrder/getInfo/${ordersn}`);
}

//getPresellOrder 获取预售订单列表
export function getPresellOrder(queryData) {
  return axios.get("/w1/presellOrder/getOrder", {
    params: queryData,
  });
}

//getOrderGoodsByOrderSn 根据订单号获取到订单商品
export function getOrderGoodsByOrderSn(ordersn) {
  return axios.get(`/w1/presellOrder/getOrderGoods/${ordersn}`);
}


//upOrderGoodsNum 修改订单商品的数量
export function upOrderGoodsNum(putData) {
  return axios.put(`/w1/presellOrder/setGoodsNum`, putData);
}


//delOrderGoods 删除预售订单的商品
export function delOrderGoods(id) {
  return axios.delete(`/w1/presellOrderGoods/${id}`);
}


//getPresellType 获取IP类型
export function getPresellType() {
  return axios.get(`/w1/presellType`);
}

export function getDownPresellGoods(params) {
  return axios.get(`/w1/presell/export`, { params: params });
}

export function importPresellGoods() {
  return axios.post("/w1/presell/import");
}
