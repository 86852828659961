var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightSide"},[_c('div',{staticClass:"rightSide_inner"},[_c('div',{staticClass:"rightSide_inner_title"},[_c('p',{staticStyle:{"font-size":"30px","color":"#333333"}},[_vm._v(" "+_vm._s(_vm.$t("My pre-sale order"))+" ")])]),_c('div',[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('Total'))+"：￥"+_vm._s(_vm.thePageTotal))]),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":_vm.$root.DownUrl + '/w1/presell/import',"accept":".xlsx,.csv","headers":_vm.uploadHeaders,"auto-upload":true,"on-success":_vm.handleImport,"on-error":_vm.handleError,"on-progress":_vm.handleUploading,"show-file-list":false}},[_c('el-button',{attrs:{"size":"small","type":""}},[_vm._v(_vm._s(_vm.$t('Import')))]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(_vm._s(_vm.$t('excelLimit')))])],1)],1),_c('div',{staticClass:"table_style"},[_c('el-table',{ref:"multipleTable",staticClass:"main-table",staticStyle:{"width":"100%","font-size":"18px","color":"#515a6e"},attrs:{"header-align":"center","border":"","data":_vm.tableData,"height":"530","tooltip-effect":"dark","header-cell-style":{ color: 'black' }}},[_c('el-table-column',{attrs:{"prop":"order_sn","label":_vm.$t('Order Number'),"align":"center","width":"220"}}),_c('el-table-column',{attrs:{"prop":"status","label":_vm.$t('Status'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.statusArrs[row.order_status]))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"goods_number","label":_vm.$t('Qty'),"align":"center"}}),_c('el-table-column',{attrs:{"prop":"total","label":_vm.$t('Amount Paid'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" ￥"+_vm._s(row.total)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"created_at","label":_vm.$t('Time'),"align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDateStr")(row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"created_at","label":_vm.$t('Time'),"align":"center","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{on:{"click":function($event){return _vm.toPreOrder(row)}}},[_vm._v(_vm._s(_vm.$t('Order Details')))])]}}])})],1)],1),_c('div',{staticClass:"footer"},[_c('el-pagination',{attrs:{"current-page":_vm.pageData.page,"page-size":_vm.pageData.pageSize,"layout":"total,  prev, pager, next, jumper","total":_vm.pageData.total,"background":""},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }